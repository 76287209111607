import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";

const TextHighlight = ({
  children,
  className,
  color = "green",
  animationDuration = "2s",
  delayBetweenAnimation = "3s",
  transformAngle = "-6deg",
  widthPercentage = 106,
  heightPercentage = 90,
  leftPercentage = "-3%",
  topPercentage = "20%",
}) => {
  const spanElementRef = useRef(null);
  const [svgSize, setSVGSize] = useState({
    width: 10,
    height: 10,
  });

  const drawSVG = useCallback(() => {
    const spanElement = spanElementRef.current;
    const spanWidth = spanElement.offsetWidth;
    const spanHeight = spanElement.offsetHeight;
    setSVGSize({
      width: spanWidth * (widthPercentage / 100),
      height: spanHeight * (heightPercentage / 100),
    });
  }, [widthPercentage, heightPercentage]);

  useLayoutEffect(() => {
    drawSVG();
  }, [drawSVG]);

  useEffect(() => {
    window.addEventListener("resize", drawSVG);
    return () => window.removeEventListener("resize", drawSVG);
  }, [drawSVG]);

  const { width, height } = svgSize;
  return (
    <span ref={spanElementRef} className={`relative ${className}`}>
      {children}
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          transform: `rotate(${transformAngle})`,
          top: `${topPercentage}`,
          left: `${leftPercentage}`,
        }}
        className={`absolute origin-[50%_50%]`}
      >
        <svg className="w-full h-full">
          <ellipse
            cx={width / 2}
            cy={height / 2}
            rx={(width / 2 - 2) > 0 ? (width / 2 - 2) : 0}
            ry={(height / 2 - 2) > 0 ? (height / 2 - 2) : 0}
            stroke={color}
            fill="none"
            strokeWidth={2}
          />
          <animate
            id="op"
            attributeName="stroke-dasharray"
            from="0,1000"
            to="1000,0"
            dur={animationDuration}
            begin={`0s;op.end+${delayBetweenAnimation}`}
          />
        </svg>
      </div>
    </span>
  );
};

export default TextHighlight;
