import $ from "jquery";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();

  return (
    <footer className="border-t border-t-black font-brown pt-8 px-4 md:px-10">
      <section className="flex flex-col md:flex-row justify-center md:gap-10">
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <Link to="/">
            <img
              className="w-40"
              src="brandlogo.svg"
              alt="Logo with brand name Rachael Roberts"
              onClick={() => {
                if (pathname === "/") {
                  $("html, body").animate({ scrollTop: 0 }, 1200);
                }
              }}
            />
          </Link>
          <p className="leading-6 pt-6 font-light tracking-tight">
            I would like to acknowledge the Gadigal people of the Eora Nation as
            the Traditional Custodians of the land on which I am from. I wish to
            pay deepest respects to Elders past, present and emerging.
            Sovereignty was never ceded. Always was and will be, Aboriginal
            land.{" "}
            <span className="flex mt-2">
              <img className="w-8 mr-2" src="flag1.jpeg" alt="a flag" />
              <img className="w-8 mr-2" src="flag2.jpeg" alt="a flag" />
              <img className="w-8 mr-2" src="flag3.jpeg" alt="a flag" />
            </span>
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="pb-2 font-bold">CONTACT</h3>
          <ul className="border-t border-t-black pt-2">
            <li className="pt-1">
              <p className="font-bold">EMAIL:</p>
              <div className="flex items-center md:items-start md:flex-col gap-2 md:gap-0">
                <p>
                  <a
                    className="hover:text-[#AE94C2] tranisiton-colors duration-500 leading-6 font-normal tracking-tight"
                    href="mailto:HELLO@RACHAELROBERTS.CO"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Link to send me email"
                  >
                    hello@rachaelroberts.co
                  </a>
                </p>
                <p className="md:pt-2 ">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/rachael___roberts/"
                    rel="noreferrer"
                    className="pr-2 hover:text-[#AE94C2] tranisiton-colors duration-500"
                    aria-label="Link to my instagram"
                  >
                    <i className="fa-brands fa-instagram text-3xl"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://open.spotify.com/user/1257152623"
                    rel="noreferrer"
                    className="hover:text-[#AE94C2] tranisiton-colors duration-500"
                    aria-label="Link to my Spotify account"
                  >
                    <i className="fa-brands fa-spotify text-3xl"></i>
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <div className="text-center font-light py-4">
        <p>
          Website Built by{" "}
          <a
            className="text-[#AE94C2]"
            href="https://swethapaleti.dev"
            target="_blank"
            rel="noreferrer"
          >
            Swetha Paleti
          </a>{" "}
          and designed by{" "}
          <a
            className="text-[#AE94C2]"
            href="https://www.rayphan.co/"
            target="_blank"
            rel="noreferrer"
          >
            Ray Phan{" "}
          </a>
          Photography by{" "}
          <a
            className="text-[#AE94C2]"
            href="https://www.instagram.com/_jailwood/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            Jack Ailwood
          </a>
        </p>
        <p>&copy; Rachael Roberts - {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
