import {Helmet} from "react-helmet"
import HeroSection from "../HeroSection";
import BookingSection from "../BookingSection";
import EventsSection from "../EventsSection";
import AboutBriefSection from "../AboutBriefSection";
import NewsletterSection from "../NewsletterSection";

const HomePage = ({ eventsSectionRef, bookingSectionRef }) => (
  <>
  <Helmet><title>Rachael Roberts - Counsellor and Yoga Teacher</title></Helmet>
  <main>
    <HeroSection />
    <AboutBriefSection />
    <EventsSection sectionRef={eventsSectionRef} />
    <BookingSection sectionRef={bookingSectionRef} />
    <NewsletterSection/>
  </main>
  </>
);

export default HomePage;
