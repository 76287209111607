import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { slideFromLeft, slideFromRight } from "../animations";
import { Helmet } from "react-helmet";

const AboutPage = () => (
  <>
    <Helmet>
      <title>About - Rachael Roberts</title>
    </Helmet>
    <main className="flex flex-col px-4 md:px-10 py-6">
      <h1 className="font-canela font-light tracking-tight text-5xl py-4 w-full lg:hidden">
        About Me
      </h1>
      <section className="flex flex-col lg:flex-row lg:gap-10 font-brown mx-auto lg:pb-2">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full lg:w-1/2 rounded-lg lg:order-last shrink-0"
        >
          <img src="rachael-aboutpage.jpg" alt="rachael roberts" />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
        >
          <h1 className="hidden lg:inline-block font-canela text-5xl tracking-tight font-light lg:pb-10">
            About Me
          </h1>
          <p className="w-full font-light text-lg mt-4 mb-3 lg:mb-0 lg:mt-0">
            My pronouns are she/her and I am originally from the lands of the
            Gadigal people of the Eora nation (Inner West, Sydney) of whom I pay
            my deepest respects to. I've been sailing the seas of self-discovery
            a great deal in the past few years. It's led me to uproot my life in
            Eora (Sydney) and move to Berlin, challenge matters like
            heteronormativity, ally-ship, what I want to be positioning myself
            against in this world, what I want to represent, how I can
            contribute to others in meaningful ways, unpacking and being
            accountable to privilege and more.
            <br />
            <br />
            <b>
              Connecting with people is my favorite thing to do on this earth.
            </b>{" "}
            Having conversations with those who seek my support on the problems
            they deem to be affecting them most has illuminated a hungry sort of
            curiosity about the ways we work in the world. I have always been
            fascinated by human nature and our similarities to one another
            despite our unique differences. I consider myself an experiential
            co-researcher, collecting conversations and experiences with
            individuals that add meaning. The feminist saying{" "}
            <b>"the personal is political"</b> is particularly guiding in my
            life right now.
          </p>
        </motion.div>
      </section>
      <section className="flex flex-col lg:flex-row lg:gap-10 font-brown mx-auto lg:mt-4">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
          className="w-full lg:w-1/2 rounded-lg shrink-0 lg:order-1"
        >
          <img src="yoga-girls.jpg" alt="group of people in yoga mudra" />
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full lg:w-1/2 lg:order-last font-light text-lg  mt-4 lg:mt-0"
        >
          <p>
            Through Yoga, I've learned how to experience{" "}
            <b>autonomous pleasure and joy.</b> Yoga is a special care practice
            that allows <b>you</b> to make <b>yourself</b> feel a certain way.
            The tools and teachings from Yoga can help anyone interested in it
            become more intimate with themselves.
          </p>
          <br />{" "}
          <p className="lg:p-3 lg:bg-[#DCF1A1] lg:ml-[-80px] lg:font-canela lg:font-normal">
            I'm passionate about working in the addiction and recovery space.
            Working with people who have a lived experience of using substances
            that have caused them harm has been an <b>essential</b> part of my
            personal development.
          </p>{" "}
          <p className="pt-4">
            I'm honored to have meaningful conversations with people from
            different cultural, sexual, and gender/genderless backgrounds facing
            a diverse scope of predicaments. Whether it's through a{" "}
            <b>verbal medium</b> or the <b>energetic exchange</b> of unified
            stillness, breath, and movement (Yoga), I strive to create a
            welcoming and supportive space.
          </p>
          <br />
          <p>
            <b>
              My mission is to use Yoga and Counselling to support understanding
              and integrate experiences of bliss and joy into everyday life.
            </b>{" "}
            If you want to learn more about my values,
            qualifications/experience, and how we can work together, please
            visit the{" "}
            <Link to="/faq">
              <b className="text-purple-400 hover:text-[#AE94C2] tranisiton-colors duration-500">
                FAQ
              </b>
            </Link>{" "}
            page.
          </p>
        </motion.div>
      </section>
    </main>
  </>
);

export default AboutPage;
