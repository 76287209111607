export const slideFromLeft = (x = -10, duration = 0.8) => ({
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration,
      ease: "easeIn",
    },
  },
  hidden: { opacity: 0, x: x },
});

export const slideFromRight = (x = 10, duration = 0.8) => ({
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration,
      ease: "easeIn",
    },
  },
  hidden: { opacity: 0, x: x },
});

export const scaleAnimation = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "easeIn",
      type: "Inertia",
    },
  },
  hidden: { opacity: 0, scale: 0.5 },
};

export const showUpAnimation = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeIn",
    },
  },
  hidden: { opacity: 0, y: 100 },
};
