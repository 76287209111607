import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";

const Navbar = ({ isEventsInView, isBookingInView }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const onMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const highlightNavbarItemIfNeeded = () => {
    if (isEventsInView) {
      setActiveSection("events");
    }

    if (isBookingInView) {
      setActiveSection("booking");
    }

    if (!isEventsInView && !isBookingInView) {
      setActiveSection(null);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", highlightNavbarItemIfNeeded);
    return () =>
      window.removeEventListener("scroll", highlightNavbarItemIfNeeded);
  });

  const { pathname } = useLocation();

  return (
    <>
      <nav className={`py-4 w-full font-brown fixed bg-[#DBD0C2] z-40`}>
        <div className="flex items-center px-4 md:px-8">
          <Link to="/">
            <img
              src="logo.svg"
              alt="logo"
              onClick={() => {
                if (pathname === "/") {
                  $("html, body").animate({ scrollTop: 0 }, 1200);
                }
              }}
            />
          </Link>
          <Link to="/">
            <img
              className="w-40 pl-3"
              src="brandlogo.svg"
              alt="Logo with brand name Rachael Roberts"
              onClick={() => {
                if (pathname === "/") {
                  $("html, body").animate({ scrollTop: 0 }, 1200);
                }
              }}
            />
          </Link>
          <div
            role="menu"
            className="flex hidden gap-10 ml-auto md:flex cursor:ponter"
          >
            <Link
              className={`hover:text-[#AE94C2]  tranisiton-colors duration-500 ${
                pathname === "/" && !activeSection ? "text-[#AE94C2]" : ""
              }`}
              to="/"
              onClick={() => {
                setIsMenuOpen(false);
                if (pathname === "/") {
                  $("html, body").animate({ scrollTop: 0 }, 1200);
                }
              }}
            >
              HOME
            </Link>
            <Link
              className={`hover:text-[#AE94C2]  tranisiton-colors duration-500 ${
                pathname === "/about" ? "text-[#AE94C2]" : ""
              }`}
              to="/about"
            >
              ABOUT
            </Link>
            <Link
              className={`hover:text-[#AE94C2] tranisiton-colors duration-500 ${
                pathname === "/events" ? "text-[#AE94C2]" : ""
              }`}
              to="/events"
            >
              RETREAT
            </Link>
            <Link
              className={`hover:text-[#AE94C2] tranisiton-colors duration-500 ${
                activeSection === "booking" && pathname === "/"
                  ? "text-[#AE94C2]"
                  : ""
              }`}
              to="/#booking"
            >
              BOOKING
            </Link>
            <Link
              to="/faq"
              className={`hover:text-[#AE94C2] tranisiton-colors duration-500 ${
                pathname === "/faq" ? "text-[#AE94C2]" : ""
              }`}
            >
              FAQ
            </Link>
          </div>
          <i
            onClick={onMenuClick}
            className="ml-auto text-2xl cursor-pointer fa-solid fa-bars md:hidden"
          ></i>
          <AnimatePresence>
            {isMenuOpen && (
              <motion.div
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, width: "16rem" },
                  collapsed: { opacity: 0, width: 0 },
                }}
                className="flex flex-col items-center gap-8 fixed right-0 bottom-0 z-40 h-full w-64 bg-[#F0E3D2] drop-shadow-xl md:hidden"
              >
                <i
                  onClick={onMenuClick}
                  className="fa-solid fa-xmark mt-10 ml-16 inline-flex justify-end text-2xl text-[#a46e42] cursor-pointer"
                ></i>
                <div role="menu" className="flex flex-col gap-6 text-2xl">
                  <Link
                    className={`hover:text-[#AE94C2] tranisiton-colors duration-500 ${
                      pathname === "/" && !activeSection ? "text-[#AE94C2]" : ""
                    }`}
                    to="/"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    HOME
                  </Link>
                  <Link
                    className={`hover:text-[#AE94C2]  tranisiton-colors duration-500 ${
                      pathname === "/about" ? "text-[#AE94C2]" : ""
                    }`}
                    to="/about"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    ABOUT
                  </Link>
                  <Link
                    to="/events"
                    className={`hover:text-[#AE94C2] tranisiton-colors duration-500 ${
                      pathname === "/events" ? "text-[#AE94C2]" : ""
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    RETREAT
                  </Link>
                  <Link
                    to="/#booking"
                    className={`hover:text-[#AE94C2] tranisiton-colors duration-500 ${
                      activeSection === "booking" ? "text-[#AE94C2]" : ""
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    BOOKING
                  </Link>
                  <Link
                    to="/faq"
                    className={`hover:text-[#AE94C2]  tranisiton-colors duration-500 ${
                      pathname === "/faq" ? "text-[#AE94C2]" : ""
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    FAQ
                  </Link>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
      <section className="flex justify-end w-full px-4 pt-20 md:px-8 md:pt-24">
        <Link to="/#booking">
          <div className="w-24 md:w-32 ellipse-bg">
            <img
              className="animate-spin-slow"
              src="bookingtext.svg"
              alt="rotating book now text"
            />
          </div>
        </Link>
      </section>
    </>
  );
};

export default Navbar;
