import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const logo = isOpen ? "answer.svg" : "question.svg";
  const logoText = isOpen ? "answer logo" : "question logo";

  return (
    <li className="border-t border-[#9D9D9D] py-6">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleOpen}
      >
        <h1 className="text-2xl tracking-tight font-light pr-2">{title}</h1>
        <button className="shrink-0" type="button">
          <img src={logo} alt={logoText} />
        </button>
      </div>
      <AnimatePresence>
        {isOpen ? (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.4 }}
            className="font-brown font-light tracking-tight">
            <div className="pt-4">
              {children}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </li>
  );
};

export default Accordion;
