import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FAQPage from "./components/FAQPage";
import AboutPage from "./components/AboutPage";
import HomePage from "./components/HomePage";
import EventPage from "./components/EventPage";
import { useInView } from "react-intersection-observer";
import $ from "jquery";

const App = () => {
  const [eventsSectionRef, isEventsInView] = useInView({ threshold: 0.3 });
  const [bookingSectionRef, isBookingInView] = useInView({ threshold: 0.3 });

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const top = $(hash).offset().top - 96;
        $("html, body").animate({ scrollTop: top }, 1200);
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <>
      <Navbar
        isEventsInView={isEventsInView}
        isBookingInView={isBookingInView}
      />
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              eventsSectionRef={eventsSectionRef}
              bookingSectionRef={bookingSectionRef}
            />
          }
        ></Route>
        <Route path="/faq" element={<FAQPage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/events" element={<EventPage />}></Route>
   </Routes>
      <Footer />
    </>
  );
};

export default App;
