import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { slideFromLeft, slideFromRight } from "../animations";
import TextHighlight from "../TextHighlight";

const BookingSection = ({ sectionRef }) => (
  <section id="booking" className="my-12 mx-4 sm:mx-8" ref={sectionRef}>
    <div className="flex flex-col sm:flex-row w-full mb-8">
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideFromLeft()}
        className="w-full md:w-1/2 lg:w-1/3 md:pr-3 mt-3 md:px-2"
      >
        <motion.h1
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="text-center py-10 font-light text-7xl font-canela tracking-tighter"
        >
          <TextHighlight
            color="#AE94C2"
            transformAngle="-6deg"
            widthPercentage={130}
            heightPercentage={100}
            leftPercentage="-13%"
            topPercentage="11%"
          >
            Bookings
          </TextHighlight>
        </motion.h1>
        <div>
          <p className="text-3xl py-6 font-canela font-light tracking-tight">
            To book a Counselling or Yoga session (or both!), please use the
            email form to get in touch with me.
          </p>
          <p className="text-2xl py-3 font-brown font-light tracking-tight">
            We can coordinate a time that works for both our schedules.
          </p>
          <p className="font-brown font-light py-3">
            If you have any questions, please check the{" "}
            <Link to="/faq">
              <span className="text-purple-400 font-normal hover:text-[#AE94C2] tranisiton-colors duration-500">
                FAQ
              </span>
            </Link>{" "}
            page or{" "}
            <Link to="/about">
              <span className="text-purple-400 font-normal hover:text-[#AE94C2] tranisiton-colors duration-500">
                About Me{" "}
              </span>
            </Link>
            section first. If your question hasn't been answered, feel free to
            send me an email. I'm happy to have a phone call with you to discuss
            our potential work together further.
          </p>
        </div>
      </motion.div>
      <div className="w-full hidden lg:block sm:w-1/3 md:pr-3 mt-3 md:px-2">
        <img
          className="w-full"
          src="booking-section-image.gif"
          alt="women doing yoga"
        />
      </div>
      <motion.form
        initial="hidden"
        whileInView="visible"
        variants={slideFromRight()}
        action="https://formspree.io/f/xayzlrzg"
        method="POST"
        className="flex flex-col md:pt-12 sm:order-2 w-full md:w-1/2 lg:w-1/3 mb-3 md:px-2 font-brown font-light tracking-tight"
      >
        <input
          name="firstName"
          required
          placeholder="Firstname"
          className="my-2 placeholder:text-[#DCF1A1] bg-[#AE94C2] text-lime-300 p-2 outline-0 border border-black"
        />
        <input
          name="lastName"
          required
          placeholder="Surname"
          className="my-2 placeholder:text-[#DCF1A1] bg-[#AE94C2] text-lime-300 p-2 outline-0 border border-black"
        />
        <input
          name="email"
          required
          type="email"
          placeholder="Email"
          className="my-2 placeholder:text-[#DCF1A1] bg-[#AE94C2] text-lime-300 p-2 outline-0 border border-black"
        />
        <input
          name="phone"
          type="tel"
          placeholder="Phone (Optional)"
          className="my-2 placeholder:text-[#DCF1A1] bg-[#AE94C2] text-lime-300 p-2 outline-0 border border-black"
        />
        <textarea
          required
          name="message"
          placeholder="Message"
          rows="8"
          className="my-2 placeholder:text-[#DCF1A1] bg-[#AE94C2] text-lime-300 p-2 outline-0 border border-black"
        />
        <button
          type="submit"
          className="bg-[#DCF1A1] font-sans font-normal px-8 py-2 border border-black rounded-full place-self-end mt-2 outline-0 hover:bg-[#AE94C2] hover:text-white tranisiton-colors duration-500"
        >
          SEND
        </button>
      </motion.form>
    </div>
  </section>
);

export default BookingSection;
