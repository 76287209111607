import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { slideFromLeft, slideFromRight } from "../animations";

const AboutBriefSection = () => {
  return (
    <section className="flex flex-col items-center md:flex-row justify-center pb-12 px-4">
      <div className="md:order-1 rounded-xl">
        <motion.img
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="h-[35rem] hidden md:block"
          src="rachael.jpg"
          alt="Rachael about brief"
        />
        <motion.img
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="md:hidden"
          src="rachael-yoga.jpg"
          alt="Rachael about brief"
        />
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideFromLeft()}
        className="w-full md:w-1/2 lg:w-2/5 md:px-4 pt-4 md:pt-0"
      >
        <h1 className="font-canela font-light tracking-tight text-5xl">
          Hi, I'm Rachael <span className="text-4xl">(she/her)</span>.
        </h1>
        <p className="font-canela font-light tracking-tight text-3xl py-4">
          I offer Counselling and Yoga practices in Berlin and online worldwide.
        </p>
        <p className="font-brown font-light tracking-tight">
          I specialise in addiction Counselling, which includes but is not
          limited to drugs, alcohol, gambling, social media, sex, shopping, and
          food. Working in the addiction space has taught me about hardship,
          survival, resilience, and unconventional thinking. I also speak with
          individuals on a wide range of topics that may be unrelated to
          addiction, which you can find more information about in the{" "}
          <Link to="/faq">
            <b className="text-purple-400 hover:text-[#AE94C2] tranisiton-colors duration-500">
              FAQ
            </b>
          </Link>{" "}
          page.
          <br />
          <br />
          Teaching Yoga has brought me closer to a version of myself that I am
          growing to like more and more. I couldn't teach Yoga without working
          as a Counsellor, and I couldn't do my work as a Counsellor without
          Yoga.
        </p>
        <Link to="/about">
          <button className="bg-[#DCF1A1] font-sans font-normal px-8 py-1 text-sm border border-black rounded-full mt-4 outline-0 hover:bg-[#AE94C2] hover:text-[white] tranisiton-colors duration-500">
            LEARN MORE
          </button>
        </Link>
      </motion.div>
    </section>
  );
};

export default AboutBriefSection;
