import { motion } from "framer-motion";
import { slideFromLeft, slideFromRight } from "../animations";
import { Helmet } from "react-helmet";

const EventPage = () => (
  <>
    <Helmet>
      <title>Retreat - Rachael Roberts</title>
    </Helmet>
    <main className="flex flex-col px-4 py-6 md:px-10">
      <h1 className="w-full py-4 text-5xl font-light tracking-tight text-center font-canela ">
        Interconnect Retreat
      </h1>
      <p className="text-xl text-center font-canela">(8-11 Feb 2024)</p>
      <span className="pb-4 text-xl text-center font-canela">
        Cedar Springs Estate, Saddleback Mountain Road, Jerrara Kiama, NSW,
        Australia
      </span>

      <section className="flex flex-col mx-auto lg:flex-row font-brown lg:pt-4 lg:pb-2">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full rounded-lg lg:w-2/5 lg:order-last shrink-0"
        >
          <img
            src="image-stack-1.png"
            alt="resort and food"
            className="w-full max-w-lg m-auto"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
          className="w-full lg:self-center lg:w-3/5"
        >
          <p className="mt-4 mb-3 text-lg font-light text-center font-brown lg:mb-0 lg:mt-0">
            <b>This retreat is not about self-care.</b> Collective care,
            interdependency, community and joy are the central themes of this
            Yoga retreat
            <br />
            <br />
            Often our expectations on how we should be 'self care-ing' can lead
            to a sense of hyper-individualism. We get so caught up in thinking
            about ourselves and what we must achieve that we lose sight of how
            good it can feel to operate as a community. The Interconnect Retreat
            provides a space for us to live and work as a whole. We think you
            should come to this retreat because each aspect of it could make up
            a retreat on its own. The luxury accomodation, the intention of each
            Yoga practice, mouth watering food, music and abundance of nature
            hugging the retreat setting are just a few things you can expect.
            <br />
            <br />
            <i>
              A co-created experiment into the anatomy of our
              interconnectedness.
            </i>
          </p>
          <div className="text-center">
            <a
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdStxOjyMF2OF2xID16gfJvhaCVjmAwt-4kNNM_OSzW2jOFIA/viewform?embedded=true"
              target="_blank"
            >
              <button className="bg-[#AE94C2] text-2xl my-5 md:mb-0 font-sans font-normal px-8 py-3 border border-black rounded-full outline-0 hover:bg-[#DCF1A1] tranisiton-colors duration-500">
                Book Now
              </button>
            </a>
          </div>
        </motion.div>
      </section>
      <section className="flex flex-col mx-auto lg:flex-row lg:gap-10 font-brown lg:mt-4">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
          className="w-full rounded-lg lg:w-2/5 shrink-0 lg:order-1"
        >
          <img
            src="image-stack-2.png"
            alt="resort"
            className="w-full max-w-lg m-auto"
          />
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full mt-4 text-lg font-light lg:w-3/5 lg:order-last lg:mt-0 lg:self-center"
        >
          <h1 className="w-full pt-4 pb-6 text-3xl font-medium tracking-tight text-center font-canela">
            LOCATION + ACCOMMODATION
          </h1>
          <p className="w-full mt-4 mb-3 text-lg font-light text-center font-brown lg:mb-0 lg:mt-0">
            Set in the rolling hills and lands of the{" "}
            <b>Whodi Whodi people on Dharawal country,</b>{" "}
            <a
              className="text-purple-400 font-normal hover:text-[#AE94C2] tranisiton-colors duration-500"
              href="https://www.cedarspringskiama.com"
              rel="noreferrer"
              target="_blank"
            >
              Cedar Springs Estate
            </a>
            (Kiama) provides the perfect home for us. It's not hard to recognise
            our interconnectedness when surrounded by this much jaw dropping
            nature. A Welcome to Country on arrival day by the local Aboriginal
            Land Council grounds us into the experience.
            <br />
            <br />
            In the spirit of this retreats theme, everyone will be sharing a
            room. But don’t worry, this doesn’t mean you’ll be crammed into a
            single bunkbed. Rather, everyone gets to enjoy spacious bedrooms
            fitted with Queens. Cedar Springs have gone above and beyond to make
            the space one of luxury and serenity. Think wide-open paddocks set
            amongst the hinterland and ocean, multiple nooks inside and outside
            to read your book in for a private moment and architecture and
            interior design perfect for hosting groups.
          </p>
        </motion.div>
      </section>
      <section className="py-8">
        <h1 className="w-full pt-4 pb-8 text-4xl font-medium text-center font-canela ">
          YOUR FACILITATORS
        </h1>{" "}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full m-auto rounded-lg lg:w-1/2 lg:order-last shrink-0"
        >
          <p className="w-full pt-4 pb-4 text-4xl font-light tracking-tight text-center font-canela">
            Rachael (she/her)
          </p>
          <img
            className="w-full max-w-lg py-8 m-auto text-center md:w-3/4"
            src="rachael-yoga-event.jpg"
            alt="rachael roberts yoga event"
          />
          <p className="w-full mt-4 mb-3 text-lg font-light text-center font-brown lg:mb-0 lg:mt-0">
            If you've been to one of Rach's practices before, you'll know her
            passion for pushing students to their edge in strong dynamic flows.
            You might know her from classes at Humming Puppy/Flow
            Athletic/Paramount Recreation Club or the event series Reflections
            which was an experiment into sound, Yoga, breath and space. The live
            DJ'd Yoga practices utilizing Eora's best handcrafted speakers
            (Prion Audio) certainly make up a huge inspiration for Rach's
            teaching style.
            <br />
            <br />“ I think dropping into a rhythm with music, movement and
            breath is what gives us an experience of flow state. It is my aim
            everyone feels this at least once throughout a practice. That's
            therapy.” Rachael is returning from Europe where she recently
            completed an additional 200 hours of teacher training on top of her
            500+ hours of education."
          </p>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full m-auto rounded-lg lg:w-1/2 lg:order-last shrink-0"
        >
          <p className="w-full pt-5 pb-4 text-4xl font-light tracking-tight text-center font-canela">
            Liza-Jane (she/her)
          </p>
          <img
            className="w-full max-w-lg py-8 m-auto text-center md:w-3/4"
            src="liza.jpg"
            alt="liza yoga event"
          />
          <p className="w-full mt-4 mb-3 text-lg font-light text-center lg:mb-0 lg:mt-0 font-brown">
            It's not often we get the time to share a table with many new faces,
            and find the sense of familiarity and connection that comes about
            when you are all there for a shared purpose. It's the intimate
            setting of a dining table that can create fire in your soul, grow
            inspiration, and provide time to reflect on your present, past and
            future life. Liza's joyous fusion of Sicilian ingredients and
            recipes with Ashram like rituals around eating surely takes us
            there.
            <br />
            <br />“ I think with this retreat we can ignite that connection to
            food and community that is so often lacking in our day to day life.
            Something you can take home with you and bring into your every day.
            I can't wait to cook for you and with you, and to get to know you
            through days of soul nourishing goodness."
            <br />
            <br />
            Liza recently completed a chef residency in Sicily where she will
            return this (European) summer as head chef for the esteemed Casa
            Lawa.
          </p>
        </motion.div>
      </section>
      <section className="flex flex-col mx-auto lg:flex-row lg:gap-10 lg:pt-4 lg:pb-2">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full rounded-lg lg:w-1/2 lg:order-last shrink-0"
        >
          <img
            src="image-stack-3.png"
            alt="resort and food"
            className="w-full max-w-lg m-auto"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
        >
          <h1 className="w-full pt-4 pb-6 text-3xl font-medium tracking-tight text-center font-canela">
            INCLUSIONS
          </h1>
          <p className="w-full mt-4 mb-3 text-lg font-light lg:mb-0 lg:mt-0 font-brown">
            <li className="py-2">
              All meals & snacks are provided throughout your stay thanks to our
              in-house Chef Liza
            </li>
            <li className="py-2">A cooking workshop with Liza</li>
            <li className="py-2">2 practices on full retreat days</li>
            <li className="py-2">
              1 extended practice on arrival and departure day
            </li>
            <li className="py-2">
              Hands on assist throughout practice and therapeutic Thai massage
              in Savasana
            </li>
            <li className="py-2">Yoga mats/blocks/bolsters/blankets</li>
            <li className="py-2">
              {" "}
              3 nights accommodation at Cedar Springs Kiama
            </li>
            <li className="py-2"> All fesh linen provided</li>{" "}
            <li className="py-2"> Australian Lief toiletries</li>
            provided <li className="py-2"> A few surprises along the way!</li>
          </p>
        </motion.div>
      </section>
      <section className="flex flex-col py-4 mx-auto lg:flex-row lg:gap-10 font-brown lg:mt-4">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
          className="w-full rounded-lg lg:w-1/2 shrink-0 lg:order-1"
        >
          <img
            src="image-stack-4.png"
            alt="resort and food"
            className="w-full max-w-lg m-auto"
          />{" "}
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromRight()}
          className="w-full mt-4 text-lg font-light lg:order-last lg:mt-0"
        >
          <h1 className="w-full pt-4 pb-6 text-3xl font-medium tracking-tight text-center font-canela">
            SAMPLE SCHEDULE
            <br />
          </h1>
          <ul className="w-full mt-4 mb-3 text-xl font-light text-center font-brown lg:mb-0 lg:mt-0">
            <li className="py-2">
              Self-led silent reflection/meditation/journaling (optional)
            </li>
            <li className="py-2">Morning practice 8am</li>
            <li className="py-2">Breakfast and swims 10:30am</li>
            <li className="py-2">Cooking workshop OR hike 12:30pm</li>
            <li className="py-2">Lunch 2:30pm</li>
            <li className="py-2">Free time</li>
            <li className="py-2">Evening practice 5pm</li>
            <li className="py-2">Dinner 7pm</li>
          </ul>
          <img
            className="w-full max-w-xl py-8 m-auto text-center md:w-3/4"
            src="schedule.jpeg"
            alt="resort"
          />
        </motion.div>
      </section>
      <section className="flex flex-col w-full mx-auto lg:flex-row font-brown lg:mt-4">
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
          className="order-1 w-full max-w-lg mx-auto rounded-lg shrink-1 md:pt-5"
        >
          <img
            src="pricing.jpeg"
            alt="pricing yoga"
            className="w-full max-w-xl m-auto md:pt-4"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={slideFromLeft()}
        ></motion.div>
        <section className="w-full py-8 lg:w-3/5 lg:self-start">
          <h1 className="w-full pt-4 pb-8 text-4xl font-medium text-center font-canela ">
            PRICING
          </h1>
          <ul className="w-full mt-4 mb-3 text-xl font-light font-brown lg:mb-0 lg:mt-0">
            <li className="py-1">
              <i>The bring a friend room:</i> <b>$990 per person</b>
            </li>
            <li className="py-1">
              - In this room you'll be sharing 1 queen bed with a
              friend/sibling/partner.
            </li>
            <li className="py-1">- Beautifully spacious.</li>
            <li className="py-1">- Shared bathroom.</li>
            <li className="py-1">
              - Views of the mountains, the ocean, or both
            </li>
            <li className="py-1">- This price covers ALL retreat inclusions</li>
            <li>
              <b>(4 spots available)</b>
            </li>
            <li className="pt-8">
              <i>The making new friends room:</i> <b>$1,190 per person</b>
            </li>
            <li className="py-1">- 2 Queen beds are in these rooms.</li>
            <li className="py-1">
              - Perfect for having your own bed space but still making a new
              friend.
            </li>
            <li className="py-1">- Beautifully spacious.</li>
            <li className="py-1">- Shared bathroom.</li>
            <li className="py-1">
              - Views of the mountains, the ocean, or both
            </li>
            <li className="py-1">- This price covers ALL retreat inclusions</li>
            <li>
              <b>(6 spots available)</b>
            </li>
            <li className="pt-8">
              - <i>The master bedroom*: </i> <b>$1,390 per person</b>
            </li>
            <li className="py-1">
              - Our master bedroom is the most luxe of them all
            </li>
            <li className="py-1">
              - Featuring your own stunning bathtub and HUGE ensuite with
              panoramic views.
            </li>
            <li className="py-1">- This price covers ALL retreat inclusions</li>
            <li>
              *This room also has a pull-out sofa bed, if there is a group of 3
              who want to share a room than this room can be arranged for{" "}
              <b>$1000 per person.</b>
            </li>
            <li>
              <b>(2-3 spots available)</b>
            </li>
          </ul>
        </section>
      </section>
      <h1 className="py-4 text-2xl text-center font-brown">
        COME FOR THE DAY, BUT DON'T STAY PRICE:
      </h1>
      <p className="py-2 text-xl font-light text-center font-brown">
        If you would like to come along for the day you are most welcome. The
        9th and the 10th (or both) are open for you to book. <b>$250/per day</b>
        . All meals, practices, activities for that day and accommodation
        facilities included. You'll be welcomed and embraced as if you were
        staying the whole time.
      </p>
      <img
        className="w-full max-w-lg py-8 m-auto text-center md:w-3/4"
        src="pricing.jpg"
        alt="yoga price"
      />

      <section className="py-2 text-xl font-light text-center font-brown">
        <p>
          If it's a full body yes, then sign up{" "}
          <a
            className="text-purple-400 font-normal hover:text-[#AE94C2] tranisiton-colors duration-500"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdStxOjyMF2OF2xID16gfJvhaCVjmAwt-4kNNM_OSzW2jOFIA/viewform?embedded=true"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
          <br />
          If you have questions, don't hesitate to reach out to
          <a
            class="hover:text-[#AE94C2] tranisiton-colors duration-500 leading-6 pl-1 font-normal tracking-tight"
            href="mailto:HELLO@RACHAELROBERTS.CO"
            target="_blank"
            rel="noreferrer"
            aria-label="Link to send me email"
          >
            hello@rachaelroberts.co
          </a>
          <br />
          Otherwise, we'll see you soon!
          <br />
          <br />
          We cannot WAIT to have you, <br />
          The Interconnect Team
          <br /> X
        </p>
      </section>
    </main>
  </>
);

export default EventPage;
