import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

import eventsData from "../events.json";
import EventCard from "../EventCard";
import TextHighlight from "../TextHighlight"
import { showUpAnimation } from "../animations";

const eventsList = eventsData.map((each) => ({
  id: uuidv4(),
  ...each,
}));

const EventsSection = ({ sectionRef }) => (
  <section
    id="events"
    className="py-6 font-canela bg-[#DCF1A1]"
    ref={sectionRef}
  >
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={showUpAnimation}
      className="px-4 md:px-8 lg:px-0 mx-auto w-full lg:w-3/5 flex flex-col justify-center items-center"
    >
      <h1 className="text-center text-6xl font-light pb-6">
        <span className="hidden sm:block">
          <TextHighlight color="#AE94C2" widthPercentage={105} transformAngle="4deg">
            Upcoming Events
          </TextHighlight>
        </span>
        <span className="sm:hidden">
        Upcoming{" "}
          <TextHighlight
            color="#AE94C2"
            transformAngle="6deg"
            topPercentage="16%"
            widthPercentage={115}
          >
           Events
          </TextHighlight>
        </span>
      </h1>
      <ul className="w-full">
        {eventsList.map((each) => (
          <EventCard key={each.id} data={each} />
        ))}
      </ul>
    </motion.div>
  </section>
);

export default EventsSection;
