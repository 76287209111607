const Marquee = () => (
  <div className="w-full py-4 mt-8 bg-[#DCF1A1] overflow-hidden">
    <div
      className="animate-marquee-fast md:animate-marquee whitespace-nowrap text-xl md:text-3xl font-brown font-light tracking-tighter"
      style={{ animationDirection: "reverse" }}
    >
      {"narrative conversations – meaning through movement – discussions of diversity – autonomous pleasure – intersectional feminism informed – ".repeat(
        5
      )}
    </div>
  </div>
);

export default Marquee;
