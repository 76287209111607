import { motion, AnimatePresence } from "framer-motion";
import jsonp from "jsonp";
import { useState } from "react";
import { slideFromRight } from "../animations";

const NewsletterSection = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [status, setStatus] = useState({
    msg: null,
    isSuccess: false,
  });

  const onEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
  };

  const onLastNameChange = ({ target }) => {
    setLastName(target.value);
  };

  const submitForm = (event) => {
    event.preventDefault();
    const url =
      "https://hotmail.us18.list-manage.com/subscribe/post-json?u=f95488122272928e11e46e2e1&amp;id=1b1ad59772&amp;f_id=00ca29e7f0";
    jsonp(
      `${url}&EMAIL=${email}&FNAME=${firstName}&LNAME=${lastName}`,
      { param: "c" },
      (_, data) => {
        const { msg, result } = data;
        if (result === "success") {
          setStatus({
            msg,
            isSuccess: true,
          });
          setFirstName("");
          setLastName("");
          setEmail("");
        } else {
          setStatus({
            msg,
            isSuccess: false,
          });
        }
      }
    );
  };

  const { msg, isSuccess } = status;

  return (
    <section className="py-8 font-canela px-4 md:px-6">
      <div className="flex flex-col sm:flex-row w-full md:px-2 sm:gap-3 lg:justify-center">
        <h1 className="text-center sm:pt-3 md:pt-5 sm:order-1 text-4xl lg:text-[2.4rem] font-light pb-4 sm:w-1/2 lg:w-1/3">
        Drop your email here for updates on happenings and new offerings
        </h1>
        <form
          onSubmit={submitForm}
          className="flex flex-col gap-4 w-full sm:w-1/2 font-light font-brown lg:w-1/3"
          target="_blank"
          method="POST"
        >
          <input
            required
            type="text"
            name="FNAME"
            value={firstName}
            onChange={onFirstNameChange}
            placeholder="Firstname"
            className="bg-[#DCF1A1] text-purple-700 p-2 outline-0 border border-black placeholder:text-[#AE94C2]"
          />
          <input
            required
            type="text"
            name="LNAME"
            value={lastName}
            onChange={onLastNameChange}
            placeholder="Lastname"
            className="bg-[#DCF1A1] text-purple-700 p-2 outline-0 border border-black placeholder:text-[#AE94C2]"
          />
          <input
            required
            type="email"
            name="EMAIL"
            value={email}
            onChange={onEmailChange}
            placeholder="Email"
            className="bg-[#DCF1A1] text-purple-700 p-2 outline-0 border border-black placeholder:text-[#AE94C2]"
          />
          <button
            className="bg-[#AE94C2] self-center font-sans font-normal px-8 py-2 border border-black rounded-full outline-0 hover:bg-[#DCF1A1] tranisiton-colors duration-500"
            type="submit"
          >
            Subscribe
          </button>
          <AnimatePresence>
            {msg ? (
              <motion.p
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={slideFromRight(10, 0.4)}
                className={`text-center font-brown font-normal ${
                  isSuccess ? "text-[#AE94C2]" : "text-red-600"
                }`}
              >
                {msg}
              </motion.p>
            ) : null}
          </AnimatePresence>
        </form>
      </div>
    </section>
  );
};

export default NewsletterSection;
