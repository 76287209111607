import { motion } from "framer-motion";
import { useState } from "react";
import TextHighlight from "../TextHighlight";
import { AnimatePresence } from "framer-motion";
import { slideFromLeft, slideFromRight } from "../animations";
import Marquee from "../Marquee";

const HeroSection = () => {
  const [openTab, setOpenTab] = useState(null);

  const onInfoIconClick = (tabNumber) => {
    window.scrollTo(0, 0);
    setOpenTab(tabNumber);
  };

  const renderBothCards = () => {
    return (
      <>
        {openTab === null || openTab === 1 ? (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideFromLeft(-10, 0.4)}
            className={`relative ${
              openTab === null
                ? "rounded-lg border border-black md:w-1/2 px-4 md:px-6 py-4"
                : "gap-3 px-8 py-8 animate-reveal-from-left md:flex-row"
            } bg-[#DCF1A1] shrink-0 w-full flex flex-col justify-between py-4`}
          >
            {openTab === null ? (
              <>
                <img
                  src="counselling.jpg"
                  className="mt-2"
                  alt="yoga trainer giving counselling therapy"
                ></img>
                <h2 className="pt-2 pb-3 text-6xl font-light tracking-tight lg:text-7xl">
                  Counselling <br />
                  informed by <br />
                  <span className="hidden lg:block">
                    <TextHighlight color="#AE94C2" transformAngle="-4deg">
                      Narrative Therapy.
                    </TextHighlight>
                  </span>
                  <span className="md:hidden">
                    <TextHighlight
                      color="#AE94C2"
                      transformAngle="-6deg"
                      topPercentage="12%"
                      widthPercentage={110}
                    >
                      Narrative
                    </TextHighlight>{" "}
                    Therapy.
                  </span>
                  <span className="hidden md:inline-block lg:hidden">
                    <TextHighlight
                      color="#AE94C2"
                      transformAngle="-6deg"
                      topPercentage="10%"
                      widthPercentage={110}
                    >
                      Narrative
                    </TextHighlight>{" "}
                    Therapy.
                  </span>
                </h2>
                <div className="ml-auto">
                  <div
                    className="flex items-center justify-center w-16 h-16 text-xl border border-black rounded-full cursor-pointer"
                    onClick={() => onInfoIconClick(1)}
                  >
                    info
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full lg:w-1/2 font-brown">
                  <p className="pb-2 text-2xl font-light font-canela">
                    I am primarily informed by Narrative Therapy. I am also
                    influenced by Intersectional Feminism and Systems Theory.
                  </p>
                  <p className="pb-2 font-light tracking-tight text-md">
                    Narrative Therapy assumes you have diverse ways to resist,
                    triumph or manage the effects of a problem. It aims to
                    externalise what can often feel like internalised problems
                    through careful questioning and close listening to the
                    stories that you tell. It's my job to walk alongside you as
                    we develop these storylines whilst acknowledging the harmful
                    effects that problems/events can cause.
                  </p>
                  <h3 className="text-xl font-canela">
                    If you are interested in:
                  </h3>
                  <div className="flex gap-2 py-2 text-lg font-light font-canela">
                    <ul className="pl-3 tracking-tight list-disc">
                      <li>Shifting blame off yourself</li>
                      <li>Exploring expectations and limitations to life</li>
                      <li>Re-evaluating societal norms</li>
                      <li>
                        Processing past/current traumas or events, in ways that
                        bring forth your unique skills, values and beliefs
                        whilst acknowledging the multiple and compounding
                        effects this may have had
                      </li>
                      <li>Realising your dreams and hopes for the future</li>
                    </ul>
                  </div>
                  <p className="pb-2 font-light tracking-tight text-md">
                    Then <b className="font-bold">I'm here</b> to provide
                    support.
                  </p>
                  <p className="pb-2 font-light tracking-tight text-md">
                    My goal is to work with you as a{" "}
                    <b>trusted co-researcher</b> in the quest of meaning-making
                    and action-taking. I'm not interested in diagnosing you.{" "}
                    instead, I focus on the <b>stories</b> that make up the
                    landscape of your life.
                  </p>
                </div>
                <div className="w-full font-light tracking-tight lg:w-1/2 font-brown">
                  <p>
                    {" "}
                    In Michael White's words,{" "}
                    <b>
                      You are ”not the problem, the problem is the problem."
                    </b>
                  </p>
                  <p className="pt-2">
                    I have had the honour of working alongside people affected
                    by a wide range of issues, including but not limited to:
                  </p>
                  <div className="flex gap-2 py-4 text-lg font-canela">
                    <ul className="pl-3 tracking-tight list-disc">
                      <li>
                        Substance Addiction (heroin, ice, cannabis, GHB,
                        ecstasy, cocaine, benzodiazepines, alcohol and more)
                      </li>
                      <li>
                        Domestic and Family abuse (including childhood sexual
                        assault),
                      </li>
                      <li>Grief and loss</li>
                      <li>Stress</li>
                      <li>Suicidal Thoughts and Self-harm</li>
                      <li>Body Image and Esteem</li>
                      <li>Anxiety</li>
                      <li>Anger/Rage</li>
                    </ul>
                    <ul className="pl-3 tracking-tight list-disc">
                      <li>Incarceration</li>
                      <li>Sexuality</li>
                      <li>Cultural Insensitivity (racism)</li>
                      <li>
                        Life changes (such as moving or career transitions)
                      </li>
                      <li>
                        Navigating systems of oppression (such as police,
                        structural violence, and the law)
                      </li>
                      <li>Homelessness or Home Insecurity</li>
                      <li>Family Dynamics</li>
                      <li>Relationship Troubles</li>
                    </ul>
                  </div>
                  <p>
                    Please don't hesitate to reach out to me if you have any
                    questions or need clarification. I am happy to chat with you
                    on the phone to discuss the potential for us to work
                    together.
                  </p>
                </div>
                <div className="w-12 h-12"></div>
                <div
                  className="absolute right-0 p-4 cursor-pointer bottom-4"
                  onClick={() => onInfoIconClick(null)}
                >
                  <img
                    className="w-12"
                    src="cross-icon.svg"
                    alt="close button"
                  />
                </div>
              </>
            )}
          </motion.div>
        ) : null}
        {openTab === null || openTab === 2 ? (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={slideFromRight(10, 0.4)}
            className={`relative ${
              openTab === null
                ? "rounded-lg border border-black md:w-1/2 px-4 md:px-6 py-4"
                : "flex-col flex justify-between gap-6 px-8 py-8 animate-reveal-from-right border-0"
            } bg-[#AE94C2] shrink-0 w-full`}
          >
            {openTab === null ? (
              <>
                <img
                  src="group-photo.jpg"
                  alt="trainer sitting with attendees while they are doing yoga"
                  className="w-[80%] md:w-[80%] lg:w-[65%] mt-2"
                />
                <h2 className="z-30 text-7xl font-light tracking-tighter text-[#ECDAC6] relative -top-[100px] lg:-top-[10%]">
                  Yoga inspired by
                  <br />
                  <TextHighlight
                    className="font-normal"
                    color="#DCF1A1"
                    transformAngle="6deg"
                  >
                    autonomy.
                  </TextHighlight>
                </h2>
                <img
                  src="yoga-pose.jpg"
                  alt="trainer helping a person by correcting her asana pose"
                  className="w-[70%] md:w-[72%] lg:w-[50%] 2xl:w-[44%] absolute right-0 bottom-0 p-[inherit] mb-2"
                />
                <div className="p-[inherit] absolute bottom-0 left-0">
                  <div
                    className="flex items-center justify-center w-16 h-16 text-xl border border-black rounded-full cursor-pointer"
                    onClick={() => onInfoIconClick(2)}
                  >
                    info
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full mx-auto font-light lg:w-2/3 font-brown">
                  <p className="pb-4 text-3xl font-canela lg:pb-5">
                    In addition to my Counselling work, I am also a Yoga
                    teacher.
                  </p>
                  <p className="pb-5 tracking-tight">
                    I started teaching in Rishikesh, India, the birthplace of
                    Yoga, under the guidance of Anand Mehoratra at Sattva Yoga
                    Academy. I've also sought mentorship with Leanne Rabb in
                    Sydney and have completed extra modules with Tahl Rinksy and
                    Bess Prescott in Byron Bay. I have over 550 hours of
                    training and mentorship in the field and am looking forward
                    to more opportunities this year.
                  </p>
                  <p className="pb-6 tracking-tight md:pb-10 lg:pb-6">
                    I'm currently interested in the concept of <b>flow state</b>{" "}
                    and curating an <b>integrated experience</b> with
                    Asana/Vinyasa, Pranayama, Meditation, and Kriya. Guided by
                    the Yoga-Vedantic tradition and modern Vinyasa sequencing, I
                    hope individuals can consciously shift their mind and body
                    on an <b>anatomical and energetic level</b> in practices
                    with lasting effects. I encourage practitioners to have{" "}
                    <b>creative autonomy</b> in practice as they know their
                    bodies best. You can expect mindful movement and breathwork
                    in strongly sequenced flows that end with a blissful
                    Savasana.
                  </p>
                  <p className="pb-4 tracking-tight md:pb-10 lg:pb-4">
                    I have taught at great studios in Sydney like; Humming
                    Puppy, Paramount Recreation Club, and Flow Athletic. I have
                    also held space for trauma-informed Yoga at residential drug
                    and alcohol rehabs and women's community organisations.
                  </p>
                  <p className="tracking-tight">
                    <b>Yoga is for everybody</b>, regardless of size,
                    flexibility, age, mobility, experience, or anything else. I
                    aim to use a <b>trauma-informed approach</b>, offering
                    different pose options, permission to rest, asking for
                    consent before hands-on adjustments, and being careful with
                    language
                  </p>
                </div>
                <img
                  className="w-12 ml-auto cursor-pointer"
                  onClick={() => onInfoIconClick(null)}
                  src="cross-icon.svg"
                  alt="close button"
                />
              </>
            )}
          </motion.div>
        ) : null}
      </>
    );
  };

  return (
    <section className="py-8 font-canela" id="hero">
      <div
        className={`w-full flex flex-col md:flex-row ${
          openTab === null ? "px-4 md:px-6 lg:px-10" : "px-0"
        } mx-auto gap-2`}
      >
        <AnimatePresence>{renderBothCards()}</AnimatePresence>
      </div>
      <Marquee />
    </section>
  );
};

export default HeroSection;
