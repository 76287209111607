import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Accordion from "../Accordion";
import feesData from "../fees.json";
import { showUpAnimation, slideFromLeft, slideFromRight } from "../animations";

const FAQPage = () => {
  return (
    <>
      <Helmet>
        <title>FAQ - Rachael Roberts</title>
      </Helmet>
      <main className="my-12 flex items-center font-canela">
        <section className="px-6 md:px-0 w-full md:w-4/5 lg:w-1/2 mx-auto">
          <motion.h1
            initial="hidden"
            whileInView="visible"
            variants={slideFromLeft()}
            className="text-5xl text-center"
          >
            FAQs
          </motion.h1>
          <motion.p
            initial="hidden"
            whileInView="visible"
            variants={slideFromRight()}
            className="text-center text-3xl tracking-tight font-light my-2 md:my-8"
          >
            Hi! Thank you for reaching out. I appreciate the steps you've taken
            as a commitment to yourself.
          </motion.p>
          <p className="text-center my-2 md:my-3 pb-4 font-brown font-light tracking-tight">
            I pay my respects to Aboriginal Elders past, present, and emerging
            and extend my deepest respect to you if you're an Aboriginal and/or
            Torres Strait Islander person.
            <br />
            <br />
            Please feel free to skim this document according to what's most
            relevant to you. If you have any additional queries, please reach
            out to me on{" "}
            <a
              className="font-normal hover:text-[#AE94C2] tranisiton-colors duration-500 "
              rel="noreferrer"
              target="_blank"
              href="mailto:HELLO@RACHAELROBERTS.CO"
            >
              hello@rachaelroberts.co
            </a>
            , and I'd be happy to chat with you on the phone.{" "}
          </p>
          <motion.ul
            initial="hidden"
            whileInView="visible"
            variants={showUpAnimation}
          >
            <Accordion title="What are you passionate about?">
              <p>
                I'm passionate about creating a culture that's free to{" "}
                <em className="font-bold">
                  question societal norms, reducing the stigma around substance
                  use
                </em>{" "}
                and{" "}
                <em className="font-bold">
                  exploring expressions of self in an ever-changing context of
                  growth, hardship and survival
                </em>
                . For the past three years, I've been working with people who
                use drugs that cause them harm. It's my privilege to hear about
                the ways they have survived or found life-affirming practices
                through addiction.
                <br />
                <br />I believe not all substances or substance use causes harm.
                For those where substances do or have caused harm, I'm
                interested in exploring how cultural, gender, racial, political,
                economic, and social factors reinforce or create substance
                misuse.
                <br />
                <br />
                And of course, I'm passionate about feeling good in my body and
                guiding others to experience this too.{" "}
                <em className="font-bold">
                  States of bliss and pleasure created through movement,
                  stillness, and breathwork
                </em>{" "}
                have allowed me to go deeper into the ancient technology and
                practice of Yoga. To share it with others brings me much joy.
              </p>
            </Accordion>
            <Accordion title="What values/ethics guide your work?">
              <p>
                I don't see the people who consult or flow with me as
                unconnected to the landscapes of my own personhood. This
                reinforces my values/ethics of{" "}
                <em className="font-bold">
                  transparency, decolonising practice, harm reduction,
                  collaboration, diversity, honesty, and story-telling.{" "}
                </em>
                I'm committed to learning ways to advocate for and promoting a
                destigmatising culture in society. Those who I've met in
                Counselling or Yoga have been my best teachers.
                <br />
                <br />
                I'm guided by the Yoga-Vedantic tradition and modern Vinyasa
                sequencing in Yoga. I aim to always use a trauma-informed
                approach, which means{" "}
                <em className="font-bold">
                  offering different options, asking for consent before any
                  hands-on adjustments, and being careful about language.
                </em>
              </p>
            </Accordion>
            <Accordion title="Do you only work with those affected by addiction?">
              <p>
                No, my work isn't limited to working with those affected by
                addiction. I welcome conversations with anyone coming up against
                any problem, whether it's related to mental health,
                relationships, identity, growth, feeling stuck, housing,
                employment, money, or other issues.
                <br />
                <br />
                Furthermore, you may not have to feel like something is
                significantly wrong to seek counseling support. If you feel{" "}
                <em className="font-bold">
                  called to a quest in self-development
                </em>
                , there are certainly some questions I'd love to ask you. You
                can learn more about some of the topics/problems I've worked
                with by clicking "info" in the counseling box.
                <br />
                <br />
                Finally, Yoga is for <em className="font-bold">everybody</em>.
                Westernised standards of Yoga may have tricked you into thinking
                otherwise but I firmly believe that this practice does not
                discriminate, no matter your size, flexibility, age, mobility,
                experience, or other factors.
              </p>
            </Accordion>
            <Accordion title="How much does sessions cost?">
              <div>
                <p>
                  Each session is approximately 60 minutes long and is priced on
                  a sliding scale to ensure accessibility for those on various
                  incomes. If you're finding it hard to keep up with payments or
                  if these prices are outside what you can afford, please
                  contact me, and we can look at other ways to make this work
                  for you.
                </p>
                <div className="flex my-4 flex-col sm:flex-row">
                  <div className="w-full sm:w-2/3 lg:w-1/2 border-2 bg-[#DCF1A1] border-black rounded-2xl mr-1 pb-4 px-4">
                    <h3 className="py-4 font-canela font-light text-3xl text-center">
                      Counselling EUR
                    </h3>
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          <th className="w-1/6"></th>
                          <th className="font-medium">Income</th>
                          <th className="font-medium">Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feesData.counsellingEUR.map((feeRow) => (
                          <tr key={feeRow.income}>
                            <td></td>
                            <td>{feeRow.income}</td>
                            <td>{feeRow.fee}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full mt-2 sm:mt-0 sm:w-2/3 lg:w-1/2 border-2 bg-[#AE94C2] border-black rounded-2xl ml-1 pb-4 px-4">
                    <h3 className=" py-4 font-canela font-light text-3xl text-center">
                      Yoga EUR
                    </h3>
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          <th className="w-1/6"></th>
                          <th className="font-medium">Income</th>
                          <th className="font-medium">Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feesData.yogaEUR.map((feeRow) => (
                          <tr key={feeRow.income}>
                            <td></td>
                            <td>{feeRow.income}</td>
                            <td>{feeRow.fee}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex my-4 flex-col md:justify-center sm:flex-row">
                  <div className="w-full sm:w-2/3 lg:w-1/2 border-2 bg-[#DCF1A1] border-black rounded-2xl mr-1 pb-4 px-4">
                    <h3 className="py-4 font-canela font-light text-3xl text-center">
                      Counselling AUD
                    </h3>
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          <th className="w-1/6"></th>
                          <th className="font-medium">Income</th>
                          <th className="font-medium">Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feesData.counsellingAUD.map((feeRow) => (
                          <tr key={feeRow.income}>
                            <td></td>
                            <td>{feeRow.income}</td>
                            <td>{feeRow.fee}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="w-full mt-2 sm:mt-0 sm:w-2/3 lg:w-1/2 border-2 bg-[#AE94C2] border-black rounded-2xl ml-1 pb-4 px-4">
                    <h3 className=" py-4 font-canela font-light text-3xl text-center">
                      Yoga AUD
                    </h3>
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          <th className="w-1/6"></th>
                          <th className="font-medium">Income</th>
                          <th className="font-medium">Fee</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feesData.yogaAUD.map((feeRow) => (
                          <tr key={feeRow.income}>
                            <td></td>
                            <td>{feeRow.income}</td>
                            <td>{feeRow.fee}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <p>
                  Yoga sessions are priced slightly higher cost to reflect the
                  longer time allocated to developing your practice or working
                  toward your specific goals.{" "}
                  <em className="font-bold">Each private is 90 minutes.</em>
                  <br />
                  <br />
                  For <em className="font-bold">corporate yoga classes</em> or
                  to book me for an event/retreat , please{" "}
                  <em className="font-bold">enquire within.</em>
                </p>
              </div>
            </Accordion>
            <Accordion title="Where do we meet for sessions?">
              <p>
                I offer counselling online via Zoom/Jitsi/Facetime or phone
                (whichever works best for you). I have found online counselling
                to be of great benefit in my own life as it allows me to feel
                settled in my
                <em className="font-bold"> own space</em>. However, not everyone
                has this privilege. Therefore, I am flexible and can meet you in
                a safer space for you such as a quiet library, café, park or we
                can go for a walk. Private Yoga classes take place in your
                residence or mine.
                <br />
                <br />
                Watch this space for updates on{" "}
                <em className="font-bold">Berlin studio</em> classes.
              </p>
            </Accordion>
            <Accordion title="How do I make payment and what happens if I need to cancel?">
              <p>
                All payment is made privately to: <b> Rachael Roberts</b>{" "}
                <em className="bg-[#DCF1A1]">BSB: 112 879 ACC: 442 756 569</em>{" "}
                (Australia) and are{" "}
                <em className="font-bold">
                  required before session goes ahead to confirm the booking
                </em>
                . I will send an email with an invoice attached 24hrs prior. For
                those in the <em className="font-bold">EU or other</em>{" "}
                countries, I will provide my{" "}
                <em className="font-bold">Paypal</em> details via email.
                <br />
                <br />
                If you need to cancel, please let me know with as much notice as
                possible so we can reschedule, and the space can be freed for
                someone else.
                <br />
                <br />
                <em className="text-[#b25415]">
                  Payment cannot be refunded with less than 24hrs notice
                </em>
                . If you wish to make another appointment to make up for the
                cancelled session, you will receive a{" "}
                <em className="font-bold">30% discount</em>. If you are running
                late, I may or may not be able to extend the session, depending
                on my availability on that day.
              </p>
            </Accordion>
            <Accordion title="Will you keep all information shared confidential?">
              <p>
                I do not talk about your stories or information with anyone else
                without prior consent. At times I may ask you if I can share
                something you say with another person who consults me if I think
                it would be beneficial. Your details will always remain
                confidential. I also seek supervision from other experienced
                practitioners to{" "}
                <em className="font-bold">
                  maintain a level of accountability toward the effects
                  conversation can have
                </em>
                . Your name will not be shared in this.
              </p>
            </Accordion>
            <Accordion title="What happens if I decide you’re not the right fit for me?">
              <p>
                I understand the need to build a connection with someone who
                resonates with you. If that's not me, I would be happy to
                provide suggestions for other Counsellors/teachers.
                <br />
                <br />I would also like to{" "}
                <em className="font-bold">promote a culture of feedback </em> to
                better my practice. If you feel there is something I could have
                done better, please tell me if you feel safe to do so. I will
                regularly check in during sessions to make sure I am asking
                questions that travel down a meaningful path for you.
              </p>
            </Accordion>
            <Accordion title="What are your qualifications/professional experiences?">
              <p>
                While most of my experience has been with women, I am committed
                to working with all diversities of gender/non-binary expression.
                <ul className="pt-2 list-disc pl-3">
                  <li>Bachelor of Social Work (UNSW)</li>
                  <li>
                    Completion of Level 1 and level 2 intensives in Narrative
                    Therapy (Dulwich Centre){" "}
                  </li>
                  <li>
                    Steps towards a Master's qualification in Narrative Therapy
                    through current involvement in a yearlong practice-based
                    course (Dulwich Centre & University of Melbourne)
                  </li>
                  <li>
                    Drug & Alcohol Counselling and groups at a Women's Community
                    Health Centre
                  </li>
                  <li>Drug & Alcohol Counselling at Women's rehabs</li>
                  <li>
                    Resident and Family Support Worker at a Mum's and Bub's
                    rehab.
                  </li>
                  <li>Over 550 hours of Yoga Teacher Training</li>
                  <li>
                    Setting up, organizing and leading Yogic based events/series
                    of events
                  </li>
                  <li>Facilitating Yoga studio classes regularly</li>
                </ul>
              </p>
            </Accordion>
          </motion.ul>
        </section>
      </main>
    </>
  );
};

export default FAQPage;
