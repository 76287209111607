import { useState } from "react";

const EventCard = (props) => {
  const { data } = props;
  const { title, location,eventLink } = data;

  const [isEventOpen, setEventOpen] = useState(true);

  const toggleEventCard = () => {
    setEventOpen(!isEventOpen);
  };

  return (
    <li className="border-b border-black last:border-0">
      {!isEventOpen && (
        <div className="flex items-center py-8 space-x-6 tracking-tighter">
          <span
            className="text-5xl font-light cursor-pointer md:w-18 md:mr-2"
            onClick={toggleEventCard}
          >
            8-11
          </span>
          <div
            className="flex flex-col cursor-pointer font-brown shrink-0"
            onClick={toggleEventCard}
          >
            <span className="font-light uppercase">FEB</span>
            <span className="font-bold uppercase">2024</span>
          </div>
          <div className="grow">
            <h2
              className="pb-2 text-4xl font-light cursor-pointer"
              onClick={toggleEventCard}
            >
              {title}
            </h2>
            <span className="hidden text-base font-light place-self-end sm:inline font-brown">
              <i className="fa-solid fa-location-dot"></i>&nbsp;{location}
            </span>
          </div>

          <a rel="noreferrer" target="_blank" href={eventLink}>
            <button className="bg-[#AE94C2] hidden sm:inline font-sans font-normal px-8 py-1 border border-black rounded-full outline-0 hover:bg-[#DCF1A1] tranisiton-colors duration-500">
              SIGN UP NOW
            </button>
          </a>
        </div>
      )}
      {isEventOpen && (
        <div className="tracking-tighter">
          <div className="py-8">
            <div
              onClick={toggleEventCard}
              className="flex items-center justify-between gap-4 my-3 cursor-pointer"
            >
              <h2 className="text-4xl font-light">{title}</h2>
              <a rel="noreferrer" target="_blank" href={eventLink}>
                <button className="bg-[#AE94C2] font-sans font-normal px-8 py-1 border border-black rounded-full outline-0 hover:bg-[#DCF1A1] tranisiton-colors duration-500">
                  SIGN UP NOW
                </button>
              </a>
            </div>
            <div className="flex w-full font-light tracking-tight font-brown">
              <div>
              <p>
                <i className="fa-solid fa-clock"></i> 08-11 Febraury 2024
              </p>
              <p className="mb-2">
                <i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;
                {location}
              </p>
              <p className="w-full sm:w-2/3">
                {" "}
                <b>This retreat is not about self-care.</b> Collective Care,
                Interdependency, Community and Joy are the central themes of
                this Yoga retreat. Simply put, everything offered in the 3
                nights, 3 ½ days is designed to get you to connect and cultivate
                joy.
                <br /> <b>We let go</b> of the idea that Yoga, Meditation,
                eating nourishing meals, exercise and rest must bring us toward
                a better version of ourselves.
                <b> We'll lean into and embrace</b> our interconnected nature.
                Unfolding into the wholeness of laughter, sweat and presence.
                Doing these things for the sake of doing them, not because they
                get us anywhere but because we get so much enjoyment out of it.
              </p>
            </div>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default EventCard;
